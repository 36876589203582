import { useStorage } from '@vueuse/core'

const state = useStorage<string[]>('searchRecord', [])

export function useSearchRecord() {
  function checkIsExist(record: string) {
    return state.value.includes(record)
  }

  function add(record: string) {
    state.value = [...new Set([record, ...state.value])].slice(0, 10)
  }

  function clear() {
    state.value = []
  }

  return {
    state,
    checkIsExist,
    add,
    clear,
  }
}
