<template>
  <div v-show="shouldHintShow" class="search-hint pb-4 rounded bg-white select-none">
    <div v-show="keywordList && keywordList.length" class="top-search">
      <div class="title py-4 px-5">
        <span class="text-base text-black-50">{{ $t('all_popular_search_title') }}</span>
      </div>
      <div class="keyword-list flex gap-2 flex-wrap px-5 py-4">
        <template v-for="k in keywordList" :key="k">
          <nd-button type="secondary" size="small" @click="handleClickKeyword(k)">{{ k }}</nd-button>
        </template>
      </div>
    </div>
    <div v-show="recordList.length" class="history">
      <div class="title py-2 px-5 flex justify-between items-center">
        <span class="text-base text-black-50">{{ $t('all_search_history') }}</span>
        <nd-button type="text" size="small" class="active" @click="handleClearSearchRecord">{{ $t('all_clear') }}</nd-button>
      </div>
      <div class="keyword-list flex flex-col md:max-h-264px scrollbar">
        <template v-for="k in recordList" :key="k">
          <div class="record hover:bg-gray-20 active:bg-gray-40 transition-colors px-5 py-3 cursor-pointer" @click="handleClickKeyword(k)">
            <span class="text-base">{{ k }}</span>
          </div>
        </template>
        <scroll-mask />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getTopKeyword } from '@/service/search'

import { useRegionStore } from '@/store/region'

const regionStore = useRegionStore()

const emit = defineEmits(['click-keyword', 'clear'])

const searchRecord = useSearchRecord()
const recordList = computed(() => searchRecord.state.value)

await useNuxtData('regionList')

const route = useRoute()

const { data: keywordList } = await useAsyncData(async () => {
  const categoryLikePageRegex = /\/regions\/\d+\/(categories|themes)/
  const isCategoryLikePage = categoryLikePageRegex.test(route.path)
  const regionId = `${regionStore.region?.region_id}`
  const categoryId = (route.params.cid || route.params.tid) as string

  const queryPayload = isCategoryLikePage ? { category_id: categoryId } : { region_id: regionId }
  const { data } = await getTopKeyword(queryPayload)
  return data
})

const shouldHintShow = computed(() => !!(recordList.value.length || keywordList.value?.length))

const handleClickKeyword = (keyword: unknown) => {
  emit('click-keyword', keyword)
}

const handleClearSearchRecord = () => {
  searchRecord.clear()
}
</script>

<style lang="scss" scoped>
.search-hint {
}
</style>
